<template>
  <div class="row">
    <div class="col-md-12">
      <b-form @submit.stop.prevent="formOnSubmit" enctype="multipart/form-data">
        <!-- Blok setelan doctor -->
        <b-card-body title="Setelan Dokter">
          <!-- Input name -->
          <b-form-group
            id="input-group-name"
            label="Nama Dokter:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Dokter"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <div class="image-input-wrapper">
            <img
              :src="uploadedImageUrl == '' ? form.image : uploadedImageUrl"
              alt=""
            />
          </div>

          <!-- Image -->
          <b-form-group
            id="input-group-image"
            label="Gambar (340 x 500):"
            label-for="input-image"
          >
            <b-form-file
              v-model="form.image"
              placeholder="Upload Gambar"
              drop-placeholder="Letakkan file di sini..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.image }}</small>
          </b-form-group>

          <!-- speciality -->
           <b-form-group
            id="input-group-specialty"
            label="Keahlian:"
            label-for="input-specialty"
          >
            <b-form-select
              id="input-specialty"
              v-model="form.specialty"
              :options="specialities"
            ></b-form-select>
            <small class="text-danger">{{ error.specialty }}</small>
          </b-form-group>

          <!-- Input text -->
          <b-form-group
            id="input-group-order"
            label="Urutan:"
            label-for="input-order"
          >
            <b-form-input
              id="input-order"
              v-model="form.order"
              placeholder="Urutan"
            ></b-form-input>
            <small class="text-danger">{{ error.order }}</small>
          </b-form-group>

          <!-- Input text -->
          <b-form-group>
            <b-form-checkbox id="input-status" v-model="form.status" switch>
              Aktifkan <b>({{ form.status ? "Ya" : "Tidak" }})</b>
            </b-form-checkbox>
          </b-form-group>

          <!-- Input text -->
          <b-form-group
            id="input-group-description"
            label="Deskripsi:"
            label-for="input-description"
          >
           <div class="document-editor">
                <ckeditor
                  :editor="editor"
                  :config="editorConfig"
                  @ready="onReady"
                  v-model="form.description"
                ></ckeditor>
            </div>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <!-- Button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="danger"
            type="button"
            @click="btnCancelOnclick"
          >
            Batal
          </b-button>
        </b-card-body>
      </b-form>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 170px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        specialty: "",
        description: "",
        image: "",
        order: null,
        status: "",
      },
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        alignment: {
          options: ["left", "right", "center", "justify"],
        },
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      // Other
      uploadedImageUrl: "",
      specialities:"",
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.image = evt.target.files[0];
    },

    btnCancelOnclick() {
      this.$root.$emit("cancelWebsiteDoctorUpdate");
      var self = this;
      Object.keys(this.error).forEach(function (key, index) {
        if (key === "image") {
          self.error[key] = "";
        } else {
          self.error[key] = "";
        }
      });
    },

     async getSpeciality(){
      let response = await module.setDoctorSelectOption('polyclinics')
      this.specialities = response.data
      this.specialities.unshift({ text: 'Pilih Keahlian', value: '', disabled: true })
    },

    async formOnSubmit() {
      if (this.form.status == false) {
        this.form.status = 0;
      } else if (this.form.status == true) {
        this.form.status = 1;
      }

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "status") {
          formData.append(key, value ? 1 : 0);
        } else {
          formData.append(key, value);
        }
      }
      let response = await module.submit(formData, this.route);
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        if (response.success.item.original == null) {
          Swal.fire(response.success.title, response.success.message, "error");
          this.error.order = "Urutan tersebut sedang aktif terpakai";
        } else {
          var self = this;
          Object.keys(this.error).forEach(function (key, index) {
            if (key === "image") {
              self.error[key] = "";
            } else {
              self.error[key] = "";
            }
          });
          this.uploadedImageUrl = "";
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.$root.$emit("updateWebsiteDoctor");
        }
      }
    },
  },
   mounted(){
    this.getSpeciality()
  }
};
</script>
<style>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
