<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Dokter Spesialis</b> website {{clinic_name}}</div>
      <b-button
        squared
        variant="success"
        @click="btnAddOnClick()"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>

    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-row no-gutters>
        <b-col md="6">
          <!-- <b-card-img
            src="/images/website-klinik-hayandra.png"
            class="rounded-0"
          ></b-card-img> -->
        </b-col>
        <b-col md="6">
          <Form
            :form="form"
            :route="'website-doctors'"
            v-if="FormDetailActv == false && FormEditActv == false"
          />
          <FormEdit
            :form="form"
            :route="'website-doctors/' + form.id"
            v-if="FormEditActv == true"
          />
          <FormDetail
            :form="form"
            :route="'website-doctors/' + form.id"
            v-if="FormDetailActv == true"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/websites/DoctorForm.vue";
import FormEdit from "@/component/websites/DoctorFormEdit.vue";
import FormDetail from "@/component/websites/DoctorFormDetail.vue";
import Table from "@/component/websites/DoctorTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "website-doctor",
  data() {
    return {
      form: {
        name: "",
        specialty: "",
        description: "",
        image: [],
        order: null,
        status: "",
      },
      FormDetailActv: false,
      FormEditActv: false,
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    };
  },
  components: {
    Card,
    Form,
    FormEdit,
    FormDetail,
    Table,
  },
  methods: {
    resetFormOnNew() {
      // reset form on new (tambah data)
      var self = this;
      Object.keys(this.form).forEach(function (key, index) {
        if (key === "image") {
          self.form[key] = [];
        } else if(key === "_method"){
          self.form[key] = "post"
        }  else {
          self.form[key] = "";
        }
      });
    },

    scrollToElement(options) {
      let el = this.$el.getElementsByClassName("pagination")[0];
      el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew()
      }
    },

    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("alert")[0];
      let er = this.$el.getElementsByClassName("alert");
      // el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew()
      }
    },

    btnAddOnClick() {
      this.scrollToElement({ behavior: 'smooth' })
    },


  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Website", route: "" },
      { title: "CMS", route: "" },
      { title: "Dokter Spesialis", route: "" },
    ]);

    // Add Website Doctor
    this.$root.$on("updateWebsiteDoctor", () => {
      // reset form on new (tambah data)
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });

    // Cancel Add Website Doctor
    this.$root.$on("cancelAddWebsiteDoctor", () => {
      // reset form on new (tambah data)
      this.scrollToTop({ behavior: "smooth" });
      this.resetFormOnNew();
    });

    //  Edit Website Doctor
    this.$root.$on("getWebsiteDoctorById", (data) => {
      this.FormDetailActv = false
      this.FormEditActv = true
      this.scrollToElement({ behavior: 'smooth' })

      if (data.status == 0) {
        data.status = false
      } else if (data.status == 1) {
        data.status = true
      }

      this.form = data
      this.form['_method'] = 'put'
    });

    // Cancel Edit
    this.$root.$on("cancelWebsiteDoctorUpdate", () => {
      this.FormEditActv = false
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });

    // Detail Web Doctor
    this.$root.$on("getWebsiteDoctorByIdDetail", (data) => {
      this.FormDetailActv = true
      this.FormEditActv = false
      this.scrollToElement({ behavior: 'smooth' })

      if (data.status == 0) {
        data.status = false
      } else if (data.status == 1) {
        data.status = true
      }
      this.form = data
    });

    // Close Detail
    this.$root.$on("closeWebsiteDoctorDetail", () => {
      this.FormDetailActv = false
      this.scrollToTop({ behavior: 'smooth' })
      this.resetFormOnNew()
    });
  },
};
</script>
